<template>
  <div
    ref="rootNode"
    class="bg-teal-50 py-16 text-neutral-800 transition-colors duration-500 ease-linear lg:py-24"
  >
    <div class="container mb-20 text-center lg:mb-24">
      <AboveTitle v-if="data.aboveTitle" :content="data.aboveTitle" />
    </div>
    <ul>
      <li
        v-for="(item, index) in data.links"
        :key="index"
        class="backgroundFill min-h-[88px] transition-all duration-100 hover:font-mono hover:font-black hover:italic hover:text-white lg:min-h-[183px]"
        :style="{
          '--background-color': item.color?.hex
        }"
        @mouseover="activeIndex = index"
        @mouseleave="activeIndex = undefined"
      >
        <div
          class="split-item container block min-h-[88px] w-[calc(100%-24px)] bg-teal-50 px-8 text-3xl uppercase transition-colors duration-300 lg:min-h-[183px] lg:w-[calc(100%-48px)] lg:px-16 lg:text-6xl"
          :style="{
            '--background-color': item.color?.hex
          }"
          role="button"
          @click="handleRedirect(item)"
        >
          <div class="background-animate" />
          <div
            class="relative z-2 flex items-center justify-between border-neutral-300 py-6 hover:border-transparent lg:py-12"
            :class="{ '!border-transparent': activeIndex && activeIndex - 1 === index }"
          >
            <div class="split-item__title whitespace-nowrap">{{ item.title }}</div>
            <div class="not-italic">
              <Button
                :class="activeIndex === index ? 'opacity-1' : 'opacity-0'"
                class="!hidden transition-opacity duration-500 lg:!inline-flex"
                :label="$t('more')"
                :size="'large'"
                :to="localePathByType(item.link?.__typename as string, item.link?.slug as string)"
                theme="dark"
                noPrefetch
              />
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { gsap } from 'gsap'
import { SplitText } from 'gsap/SplitText'
import type { SectionSplitRecord } from '~/types/generated'

const { localePathByType } = useLocalePathByType()
const router = useRouter()
const rootNode = ref<HTMLElement | null>()

defineProps({
  data: {
    type: Object as PropType<SectionSplitRecord>,
    default: () => {}
  }
})

const activeIndex = ref<number | undefined>()
const isAnimationFinished = ref(false)

function handleRedirect(link: any) {
  router.push(
    localePathByType(link.reference?.__typename as string, link.reference?.slug as string)
  )
}

const { stop } = useIntersectionObserver(
  rootNode,
  ([{ isIntersecting }], _observerElement) => {
    if (isIntersecting && !isAnimationFinished.value) {
      const splitItemNodes = computed<HTMLElement[]>(() =>
        Array.from((rootNode.value as HTMLElement).querySelectorAll('.backgroundFill'))
      )
      gsap.registerPlugin(SplitText)

      splitItemNodes.value.forEach((box, index) => {
        const tl = gsap.timeline()

        const title = box.querySelector('.split-item__title')
        const textSplit = new SplitText(title, { type: 'lines', linesClass: 'split-child' })
        const textSplitChars = textSplit.lines
        tl.to(box, { duration: 0.1, '--bgWidth': '50%' }, index * 0.4)
        tl.from(
          textSplitChars,
          {
            yPercent: 350,
            stagger: {
              each: 0.06
            },
            onComplete: () => {
              isAnimationFinished.value = true
            }
          },
          index * 0.4
        )
      })
    }
  },
  {
    threshold: 0.1
  }
)

onUnmounted(() => {
  stop()
})
</script>

<style lang="postcss">
.split-item {
  --background-color: white;
  position: relative;
  overflow: hidden;
  z-index: 2;

  .background-animate {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: var(--background-color); /* Initial color */
    transition: width 0.5s ease-out; /* Adjust the duration as needed */
  }

  &:hover {
    .background-animate {
      width: 100%;
    }
  }

  .split-child > div {
    padding-right: 1rem;
  }
}

.backgroundFill {
  position: relative;
  --bgWidth: 0;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: var(--bgWidth);
    background: var(--background-color);
    height: 100%;
    transition: width 1s ease-in-out;
  }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    background: var(--background-color);
    width: var(--bgWidth);
    top: 0;
    height: 100%;
    transition: width 1s ease-in-out;
  }

  &:hover::before {
    width: var(--bgWidth);
    transition: width 0.5s ease-in-out;
  }

  &:hover::after {
    width: var(--bgWidth);
    transition: width 0.5s ease-in-out;
  }
}
</style>
